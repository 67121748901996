<template>
  <div class="outer">
    <div
      class="academy settingStyle"
      :style="'backgroundImage:url(' + imgList.zhutu + ')'"
      @click="briefIntroduction"
    >
      <div class="academyCont">
        <div class="contOne">
          <div class="titleOne">{{ $t("aboutUs.aboutUs_titleOne") }}</div>
          <div class="xian"></div>
          <div class="titleOne">{{ $t("aboutUs.aboutUs_titleTwo") }}</div>
        </div>
        <div class="ContTwo">
          <div>{{ $t("aboutUs.aboutUs_see") }}</div>
          <img src="@/assets/img/checkbai.png" alt="" />
        </div>
      </div>
    </div>

    <div class="outerTwo">
      <div
        v-for="(item, index) in $t('aboutUs.aboutUs_aboutList')"
        :key="index"
        class="aboutItem"
        @click="aboutList(item)"
      >
        <img :src="item.bjImg" alt="" class="bjImg" />
        <div class="aboutCont">
          <div class="title">{{ item.name }}</div>
          <div class="ContTwo">
            <div>{{ $t("aboutUs.aboutUs_see") }}</div>
            <img src="@/assets/img/checkbai.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-12 11:04
 */
export default {
  data() {
    return {
      imgList: "",
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.getBigPicture();
    this.handleScrollbox();
  },
  methods: {
    /**
     * 模块切换
     * 刘嘉鑫
     * 2022-8-12
     */
    aboutList(item) {
      this.$router.push({
        path: item.path,
      });
    },

    /**
     * 简介
     * 刘嘉鑫
     * 2022-8-12
     */
    briefIntroduction() {
      this.$router.push({
        path: "briefIntroduction",
      });
    },

    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-19
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgCenter",
        data: {
          type: 4, // 类型:1=行业服务,2=行业科普,3=人才招聘,4=关于我们,5=技术研发
        },
      }).then((res) => {
        console.log("大图", res);
        this.imgList = res.img_list;
        this.$t("aboutUs.aboutUs_aboutList")[0].bjImg = res.img_list.shimyj;
        this.$t("aboutUs.aboutUs_aboutList")[1].bjImg = res.img_list.tuand;
        this.$t("aboutUs.aboutUs_aboutList")[2].bjImg = res.img_list.zizhi;
        this.$t("aboutUs.aboutUs_aboutList")[3].bjImg = res.img_list.fazdsj;
      });
    },

    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-8-23
     */
    handleScrollbox() {
      setTimeout(() => {
        this.$(".aboutItem").addClass("showDiv");
      }, 300);

      // this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      // if (this.currentScroll >= this.$(".outerTwo").offset().top - 200) {
      //   this.$(".aboutItem").addClass("showDiv");
      // }
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px 40px;
}

.outer > div {
  cursor: pointer;
}

.academy {
  width: 100%;
  height: 600px;
  margin-bottom: 78px;
  .academyCont {
    color: #ffffff;
    padding: 373px 50px 30px;
    .contOne {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .titleOne {
        font-size: 28px;
        font-family: FZFengYaSongS-GB;
        font-weight: bold;
        color: #ffffff;
      }

      .xian {
        width: 2px;
        height: 34px;
        background: #ffffff;
        margin: 0 20px;
      }
    }
  }
}

.outerTwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 50px;
  .aboutItem {
    width: 46%;
    height: 280px;
    margin-bottom: 80px;
    overflow: hidden;
    transition: all 0.8s ease-out 0s;
    transform: translate(-100px, 0px);
    opacity: 0;
    position: relative;

    .bjImg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      transition: all 0.9s !important;
    }
    .aboutCont {
      padding: 70px 60px 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 100px;
      }
      .ContTwo {
        justify-content: flex-end;
      }
    }
  }

  .aboutItem:hover {
    > .bjImg {
      transform: scale(1.2) !important;
    }
  }

  .aboutItem:nth-child(1),
  .aboutItem:nth-child(3) {
    transition-delay: 0.2s;
  }

  .aboutItem:nth-child(2),
  .aboutItem:nth-child(4) {
    transition-delay: 0.4s;
  }
}

.ContTwo {
  display: flex;
  align-items: center;

  > div {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
  > img {
    width: 20px;
    height: 14px;
    margin-left: 10px;
  }
}

.academyCont:hover,
.aboutCont:hover {
  > .ContTwo {
    img {
      animation: checkImg 0.7s linear infinite;
      position: relative;
    }
  }
}
</style>